import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../utils/UserContext";
import { Link, useHistory } from "react-router-dom";

const vIA = 217;
function Score (){

    const {user} = useUser();

    const history = useHistory();
   
    useEffect(() => {
        if(!user){
            history.push('/')
            return
        }
        const handleKeyPress = (event:KeyboardEvent) => {
        if (event.key === 'a' || event.key === 'A' || event.key === 'Enter') {
            // Navigate to the "/otherpage" route when "A" is pressed
            history.push(user ? '/best_score' : '/');
        }
        };

        // Add the event listener when the component mounts
        document.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
        document.removeEventListener('keydown', handleKeyPress);
        };
    }, [history])

    const formatedTime = (time: number | null): string => {
        if(!time){return 'NA'};
        const totalSeconds = Math.round(time);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return minutes > 0 ? `${minutes} min ${seconds} s` : `${seconds} s`;
    }

    const numberOfIA = () => {
        if(!!user && !!user.last_duration) {
            return `${Math.round(user.last_duration * vIA)}`;
        } else {
            return null;
        }
    }

    const getScoreMessage = ():string => {
        if(!user || !user.last_right_answer) {
            return ''
        }
        const score = user.last_right_answer;
        if (score <= 4) {
            return 'Aïe aïe aïe, il semblerait que vous ayez besoin d’aide pour reconnaître des maladies !'
        };
        if (score <= 9) {
            return 'La chance ne vous a pas souri et il ne faudra pas non plus compter dessus pour protéger votre vignoble !'
        };
        if (score <= 14) {
            return 'Vous avez les bases, un petit coup de pouce vous amènerait loin !'
        };
        if (score <= 17) {
            return 'Votre connaissance en maladies du vignoble a été mise à l’épreuve, et vous avez su relever ce défi !'
        };
        if (score <= 19) {
            return 'Vous êtes un as en identification de maladies !'
        };
        return 'Exceptionnel ! La vigne n’a plus aucun secret pour vous ! On vous embauche ?'
    }

    return (
        <div className="container">
            <h1>VOTRE SCORE EST</h1>
            {!!user && 
            <div className="flexbox-column  text-container">
                <h3 className="large-header green-text">{user.last_score}</h3>
                <h3>{getScoreMessage()}</h3>
                <h3>Vous avez mis <span className="green-text">{formatedTime(user.last_duration)}</span> pour répondre à toutes les questions avec <span className="green-text">{user.last_right_answer}</span> bonnes réponses sur 20.</h3>
                
                <h3>Pendant ce temps là, notre IA a traité <span className="green-text">{numberOfIA()}</span> photographies.</h3>
                
            </div>
            }
            <div className='shadow-wrapper'>
                <button className="game-button"><Link to='/best_score'>Voir les meilleurs scores</Link></button>
            </div>
        </div>
        

    )
}

export default Score