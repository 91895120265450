import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

interface GamePageProps {
    userId: number | null | undefined;
    closeRules: Dispatch<SetStateAction<boolean>>; // Define the type of the prop
  }
function GameRules (prop: GamePageProps){

    const history = useHistory();

    useEffect (()=>{
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    
      }, [])

    const handleKeyPress = (event: KeyboardEvent) => {
    if(event.key === 'a' || event.key === 'A' || event.key === 'Enter') {
        startGame();
    };
    if(event.key === 'b' || event.key === 'B') {
        prop.closeRules(false);
        if(!!prop.userId){
            history.push('/user_exist')
        }
    };
    }
    const startGame = ()=>{
         history.push(`/start/${prop.userId}`);        
    }

    return (
        <div className="rules-page text-container">
            <div className="flexbox-column">
                <h2>Règles du jeu</h2>
                <p>Une série de 20 images prise par capteur embarqué Chouette vous sera présentée.</p>
                <p>L'objectif est de classer ces photos selon l'état de vigne :</p>
                <ul>
                    <li>Atteinte par du mildiou;</li>
                    <li>Atteinte par l'esca;</li>
                    <li>Flavescence dorée;</li>
                    <li>Saine.</li>
                </ul>
                <p>Pour les classer, utilisez les flèches de la manette pour valider votre réponse et passer à l'image suivante.</p>
                <p>A l'issu des 20 questions, votre performance sera jugée selon l'exactitude de vos réponses et le temps mis.</p>
                <p>Une surprise attend la personne ayant réalisé le meilleur score !</p>
            </div>
            
            
                {!prop.userId &&
                    <div className="shadow-wrapper flexbox-end"><button className="game-button" onClick={()=>{prop.closeRules(false)}}>Fermer<span>(B)</span></button></div>                
                }
                {!!prop.userId && 
                <div className="flexbox-end"  >
                    <div className="shadow-wrapper"><button className="game-button" onClick={startGame}>Commencer<span>(A)</span></button></div>
                </div>
                
                }
        </div>
        
    )
}

export default GameRules