import React, { Dispatch, SetStateAction, useEffect } from "react";

interface AlertProps {
    message: string;
    setOpenAlert: Dispatch<SetStateAction<boolean>>;
  }
function AlertBox (prop: AlertProps){

    useEffect (()=>{
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    
      }, [])

    const handleKeyPress = (event: KeyboardEvent) => {
    if(event.key === 'ENTER' ) {
        prop.setOpenAlert(false);
    };
    
    }

    return (
        <div className="alertContainer flexbox-column">
            <div className="alertBox">
                <p>{prop.message}</p>               
                <div className="shadow-wrapper flexbox-end"><button className="game-button" onClick={()=> prop.setOpenAlert(false)}>D'accord</button></div>
            </div>
            
        </div>
        
    )
}

export default AlertBox