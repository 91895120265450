import React, { useState, useEffect } from 'react';
import chrono from '../img/chonometre.png'

function Chronometer () {
  const [time, setTime] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(true); // Start running on mount

  // Effect to start the chronometer when the component mounts
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 10);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  // Effect to reset the chronometer when the component unmounts
  useEffect(() => {
    return () => {
      resetChronometer();
    };
  }, []);

  const formatedTime = () => {

  }

  const getMinutes = (value: number) =>{
    return Math.floor(value / 6000);
  }

  const getSeconds = (value: number) => {
    return Math.floor(value % 6000 / 100);
  }

  const getMilliseconds = (value: number) => {
    return value % 100;
  }
  const computeTwoDigitNumber = (value:number): string =>{
    if(value < 10) {
      return '0'+value;
    }else{
      return value.toString()
    }
  }

  const split = (value:number) => {
    return `${computeTwoDigitNumber(getMinutes(value))}:${computeTwoDigitNumber(getSeconds(value))}`
  }
  const resetChronometer = () => {
    setIsRunning(false);
    setTime(0);
  };

  return (
    <div className='chronometer'>
      <div>
        <img style={{width:"150px"}} src={chrono} alt=""/>
      </div>
      <p className='timer'>{split(time)}</p>
    </div>
  );
};

export default Chronometer;
