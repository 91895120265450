import axios, { AxiosResponse } from 'axios';
import { UserData, UserForm } from '../utils/interfaces';

let API_BASE_URL: string ;

if (process.env.NODE_ENV == 'development') {
  API_BASE_URL = 'http://localhost:8000/api';
}
else {
  API_BASE_URL = 'https://game-api.chouette.vision/api'
}


const userService = {
  postUser: async (userData: UserForm): Promise<AxiosResponse> => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users/user-create/`, userData);
      return response;
    } catch (error) {
      throw error;
    }
  },

  retrieveUser: async (email: string): Promise<AxiosResponse> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/retrieve-user/${email}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  putUser: async (userId:number, userData: UserForm): Promise<AxiosResponse> => {
    try {
      const response = await axios.put(`${API_BASE_URL}/users/user-update/${userId}`, userData);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getUserScoreList: async (): Promise<AxiosResponse> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/user-list-by-score/`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default userService;