// AuthContext.tsx
import React, { createContext, useState, useContext } from 'react';
import { UserData } from './interfaces'

interface UserContextType {
  user: UserData | undefined;
  newUser: (json:any) => void;
  cleanUser: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an AuthProvider");
  }
  return context;
};

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserData | undefined>(undefined);

  const newUser = (json:any) => {
    setUser(new UserData(json));
  };

  const cleanUser = () => {
    setUser(undefined);
  };

  return (
    <UserContext.Provider value={{ user, newUser, cleanUser }}>
      {children}
    </UserContext.Provider>
  );
};
