import React, { useEffect } from "react";
import logo from "../img/logo-chouette-HD.png"
import { useHistory } from "react-router-dom";

function HomePage (){
    const history = useHistory();

    useEffect(() => {
        const handleKeyPress = (event:KeyboardEvent) => {
            console.log(event.key)
        if (event.key === 'a' || event.key === 'A' || event.key === 'Enter') {
            // Navigate to the "/otherpage" route when "A" is pressed
            history.push('/user');
        }
        };

        // Add the event listener when the component mounts
        document.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
        document.removeEventListener('keydown', handleKeyPress);
        };
    }, [history])

    return (
        <div className="container">
            <h1 className="large-header">Affrontez l'IA</h1>
            <img className="home-logo" src={logo} alt="Chouette" />
            <h1>Appuyez sur <span className="green-text">"A"</span> pour commencer</h1>
        </div>
        
    )
}

export default HomePage