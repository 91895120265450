import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import userService from "../services/UserService";
import { useUser } from "../utils/UserContext";
import { UserForm } from "../utils/interfaces";
import { useHistory } from "react-router-dom";
import AlertBox from "../components/AlertBox";

function UserExist (){

    const { user, newUser } = useUser();

    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const [changInfo, setChangeInfo] = useState(false);
    const [formData, setFormData] = useState<UserForm>({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        company: '',
        phone_number: '',
        job: '',
        newsletter: false,
        rgpd_accept: true,
      });
    const [checkNewsLetter, setCheckNewsLetter] = useState<boolean>(false);
    

    useEffect(()=>{
        retriveUser();
    }, [])
    const retriveUser = async () => {
        if(!user || !user.email) {
            history.push('/')
            return
        };
        try {
            const response = await userService.retrieveUser(user.email);
            user.userFromRetrieve(response.data);
            const { username, company, first_name, last_name, phone_number, job, newsletter } = response.data;
            setFormData({ username, email: user.email, company, first_name, last_name, phone_number, job, newsletter, rgpd_accept: true });
            setCheckNewsLetter(newsletter);
        } catch (err) {
            console.log(err)
        }
    }
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChangeInfo(true);
        const { name } = event.target;
        if (name === 'newsletter'){
          const newsletter = !checkNewsLetter
          setCheckNewsLetter(newsletter)
          setFormData({ ...formData, [name]:newsletter})
        } else {
          return
        }
      }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChangeInfo(true);
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        checkBlank();
      };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if(!user || !user.pk) return;
        userService.putUser(user.pk, formData)
            .then((response)=> {
                newUser(response.data)
                setChangeInfo(false);
            })
            .catch ((error: any) => {
                if(!!error.response.data.phone_number) {
                    setMessage("Le format du numéro de téléphone doit comporter l'indicatif du pays.");
                    setOpenAlert(true);
                    setChangeInfo(true);
                  }
                console.warn('Error sending user data:', error); 
                })
        
    }
    const checkBlank = () => {
        if(formData.phone_number === '') {
          setFormData({...formData, phone_number: null});
        }
        if(formData.job === '') {
          setFormData({...formData, job: null})
        }
      }

    const history = useHistory();
    const startGame = ()=>{
        if(!user) return
        history.push(`/start/${user.pk}`);        
    }

    return (
        <div className="container">
            <h2>Il semblerait que vous ayez déjà joué</h2>
            <h3>Vous aviez entré les coordonnées suivantes :</h3>
            <form className="user-form" onSubmit={handleSubmit}>
                <div className="form-content">
                    <div>
                        <label htmlFor="username">Pseudo<sup>*</sup>:</label>
                        <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        required
                        />
                    </div> 
                    <div>
                        <label htmlFor="first_name">Prénom<sup>*</sup>:</label>
                        <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name">Nom<sup>*</sup>:</label>
                        <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div>
                        <label htmlFor="company">Entreprise<sup>*</sup>:</label>
                        <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone_number">Téléphone:</label>
                        <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={!!formData.phone_number ? formData.phone_number : ''}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="job">Fonction:</label>
                        <input
                        type="text"
                        id="job"
                        name="job"
                        value={!!formData.job ? formData.job : ''}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className=''>
                        <div>
                            <input
                            className='checkbox'
                            type="checkbox"
                            name="newsletter"
                            checked={checkNewsLetter}
                            onChange={handleCheckboxChange}
                            />
                            <span>Je souhaite m'abonner à la newsletter.</span>
                        </div>                       
                    </div>   
                </div>   
                
                <div className="flexbox-end">
                    {changInfo ? 
                        <div className='shadow-wrapper'>
                        <button className="game-button" type="submit">Submit</button>
                        </div>
                        :
                        <div className="flexbox-row">
                            <div className='shadow-wrapper'>
                                <button className="game-button" type="button" onClick={()=> setChangeInfo(true)}>Corriger mes infos</button>
                            </div>
                            <div className='shadow-wrapper'>
                                <button className="game-button" type="button" onClick={startGame}>Rejouer</button>
                            </div>                        
                        </div>
                    }
                </div>
                
            </form>
            {openAlert && 
                <AlertBox setOpenAlert={setOpenAlert} message={message} />
            }
        </div>
        
    )
}

export default UserExist