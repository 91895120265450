import axios, { AxiosResponse } from 'axios';
import { UserData, UserForm } from '../utils/interfaces';

let API_BASE_URL: string;

if (process.env.NODE_ENV == 'development') {
  API_BASE_URL = 'http://localhost:8000/api';
}
else {
  API_BASE_URL = 'https://game-api.chouette.vision/api'
}

const imageService = {
  creatSession: async (userId: number): Promise<AxiosResponse> => {
    const request = {'user': userId}
    try {
      const response = await axios.post(`${API_BASE_URL}/scores/session-create/`, request);
      return response;
    } catch (error) {
      throw error;
    }
  },

  updateScore: async (scoreId: number, label: string): Promise<AxiosResponse> => {
    const request = {"label": label}
    try {
      const response = await axios.put(`${API_BASE_URL}/scores/score-update/${scoreId}`, request);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default imageService;