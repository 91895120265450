import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GameScore, GameSession } from "../utils/interfaces";
import imageService from "../services/ImageService";
import { useUser } from "../utils/UserContext";
import triangleBlue from "../img/triangle_blue.svg";
import triangleOrange from "../img/triangle_orang.svg";
import Chronometer from "../components/Chronometer";
import loading from "../img/loading.png"

function GamePage (){

    const {userId} = useParams<{userId:string}>()

    const {user} = useUser();

    const [session, setSession] = useState<GameSession | undefined>(undefined);
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<GameScore | undefined>(undefined);
    const [chosen, setChosen] = useState<string>('');
    const [isRightAnswer, setIsRightAnswer] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const history = useHistory();
    useEffect(() => {
        if(!!userId && userId !== ''){
            imageService.creatSession(+userId)
            .then((response)=>{
                const session = new GameSession(+userId, response.data)
                setSession(session);
                setCurrentImage(session.score_set[0]);
                setIsLoading(false)
            })
            .catch((error) => {
                console.warn(error);
            })
        } else {
            history.push('/')
        }
        
    }, [])

    useEffect (()=>{
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    
      }, [currentImage, imageIndex, history])

    const handleKeyPress = (event: KeyboardEvent) => {
        switch(event.key){
            case 'ArrowUp':
                sendLabel('ESCA');
                break;
            case 'ArrowLeft':
                sendLabel('MILDIOU');
                break;
            case 'ArrowRight':
                sendLabel('SAIN');
                break;
            case 'ArrowDown':
                sendLabel('FLAVESCENCE');
                break;
        }

    }
    const sendLabel = async (label: string)=>{
        if(!currentImage) return
        setChosen(label);
        const response = await imageService.updateScore(currentImage.pk, label)
        if(response.data.session_ended){
            if(!!user){
                user.updateLastScore(response.data);
                history.push('/score');
            }
        } else {
            if(response.data.good_label){
                setIsRightAnswer(true);
            } else {
                setIsRightAnswer(false);
            }
            setTimeout(()=>{
                nextImage();
            }, 500)
        }
    }
    const nextImage = () => {
        if(!session){
            return
        }
        reset();
        const length = session.score_set.length;
        if(imageIndex + 1 < length){
            const next = imageIndex + 1;
            setCurrentImage(session.score_set[imageIndex+1]);
            setImageIndex(next);
        }
    }

    const reset =()=>{
        setChosen('');
        setIsRightAnswer(null);
    }

    const getBorder = ():string => {
        let borderClass: string = 'image-container';
        switch(isRightAnswer){
            case true: 
                borderClass +=' right-border';
                break;
            case false:
                borderClass += ' error-border';
                break;
            default:
                borderClass += '';
        }
        return borderClass
    }


    return (
        <div className="container">
            <h1>De quelle maladie est-ce le symptôme</h1>
            
            
            <div className="flexbox-row">
                {!!currentImage && !isLoading &&
                    
                    <div className={getBorder()}>
                        {isRightAnswer === false &&
                            <div className="answer">
                            {currentImage.image.label}
                            </div>
                        }
                        
                        <img src={currentImage.image.image} alt="image not found"></img>
                    </div>
                                      
                }
                {isLoading && 
                    <div className="image-container">
                        <img src={loading} alt="is loading"></img>
                    </div>
                }
            
            <div className="flexbox-column">
                <div className="flexbox-center">
                    <p className="label">ESCA</p>
                    <button className="arrow-button" onClick={()=>sendLabel('ESCA')}>
                        {chosen === 'ESCA' ?
                        <img style={{width:'80px'}} src={triangleOrange} /> :
                        <img style={{width:'80px'}} src={triangleBlue} />
                        }
                    </button>                   
                </div>
                <div className="flexbox-row">
                    <p className="label">MILDIOU</p>
                    <button className="arrow-button" onClick={()=>sendLabel('MILDIOU')}>
                    {chosen === 'MILDIOU' ?
                        <img style={{width:'80px', transform:'rotate(270deg)'}} src={triangleOrange} /> :
                        <img style={{width:'80px', transform:'rotate(270deg)'}} src={triangleBlue} />
                    }
                    </button>
                <div style={{width:'80px'}}></div>
                
                    <button className="arrow-button" onClick={()=>sendLabel('SAIN')}>
                        {chosen === 'SAIN' ?
                            <img style={{width:'80px', transform:'rotate(90deg)'}} src={triangleOrange} /> :
                            <img style={{width:'80px', transform:'rotate(90deg)'}} src={triangleBlue} />
                        }
                    </button> 
                    <p className="label">SAINE</p>
                    
                </div>
                <div className="flexbox-center">
                    <button className="arrow-button" onClick={()=>sendLabel('FLAVESCENCE')}>
                        {chosen === 'FLAVESCENCE' ?
                            <img style={{width:'80px', transform:'rotate(180deg)'}} src={triangleOrange} /> :
                            <img style={{width:'80px', transform:'rotate(180deg)'}} src={triangleBlue} />
                        }
                    </button>
                    <p className="label">FLAVESCENCE</p>  
                </div>

            </div>

            <Chronometer />

            </div>
        </div>    
    )
}

export default GamePage