import React, {FunctionComponent} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import UserInfoPage from './pages/UserInfos';
import HomePage from './pages/Home';
import UserExist from './pages/UserExist';
import GamePage from './pages/GamePage';
import Score from './pages/Score';
import BestScore from './pages/BestScore';
import { UserProvider } from './utils/UserContext';
import './App.css';

import "./fonts/PressStart2P-Regular.ttf";
  
const App: FunctionComponent = () => {
    
 return (
  <UserProvider>
    <Router>
      <div>
        <Switch>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/user" component={UserInfoPage} />
          <Route exact path="/user_exist" component={UserExist} />
          <Route exact path="/start/:userId" component={GamePage} />
          <Route exact path="/score" component={Score} />
          <Route exact path="/best_score" component={BestScore} />
          <Route exact path='/' component={HomePage} />
          <Route exact path='*' component={HomePage} />    
        </Switch>
      </div>
    </Router>
  </UserProvider>
  
  
  
 )
}
  
export default App;