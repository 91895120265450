export interface UserForm {
    username: string;
    email: string;
    first_name: string ;
    last_name: string ;
    company: string;
    phone_number: string | null;
    job: string | null;
    newsletter: boolean;
    rgpd_accept: boolean;
}

export class UserData {
    username: string = '';
    email: string = '';
    first_name: string = '';
    last_name: string = '';
    company: string = '';
    job: string = '';
    phone_number: string = '';
    pk: number | null = null;
    best_score: number | null = null;
    last_right_answer: number | null = null;
    last_score: number | null = null;
    last_duration: number | null = null;
    rank: number = 0;
    newsletter: boolean = false;


    constructor(json: any) {
        if(!!json){
            this.username = json.username;
            this.email = json.email;
            this.company = json.company;
            this.first_name = json.first_name;
            this.last_name = json.last_name;
            this.pk = json.pk;
            this.newsletter = json.newsletter;
            if(!!json.phone_number && json.phone_number !== null){
                this.phone_number = json.phone_number
            }
            if(!!json.job && json.job !== null){
                this.job = json.job
            }
        }
    }
    updateLastScore(score: any) {
        this.last_duration = score.session_time_passed;
        this.last_right_answer = score.session_total_score;
        this.last_score = score.session_computed_score;
    }

    userFromRetrieve (json: any) {
        this.pk = json.pk;
        this.username = json.username;
        this.company = json.company;
        this.first_name = json.first_name;
        this.last_name = json.last_name;
        this.best_score = json.best_score;
    }

    updateBestScore (score: UserScore, rank: number) {
        this.best_score = score.score;
        this.rank = rank;
    }

  }

  export class GameScore {
    pk: number;
    image: {
        pk: number;
        image: string;
        label: string;
    };
    
    constructor(json: any) {
        this.pk = json.pk;
        this.image = json.image;
    }
  }

  export class GameSession {
    pk: number;
    user: number;
    score_set: GameScore[] = [];

    constructor(userId:number, json?: any) {
        this.pk = json.pk;
        this.user = userId;
        this.score_set = this.addImage(json.score_set)
    }

    addImage(imageList: []): GameScore[] {
        //const images: [] = JSON.parse(imageList);
        return imageList.map(image => new GameScore(image))
    }
  }

  export class UserScore {
    user_pk: number;
    username: string;
    score: number;
    rank: number;

    constructor(json: any, rank: number) {
        this.user_pk = json.pk;
        this.username = json.username;
        this.score = json.best_score;
        this.rank = rank;
    }
  }
