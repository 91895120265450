import React, { useEffect, useState } from "react";
import userService from "../services/UserService";
import { UserData, UserScore } from "../utils/interfaces";
import { useUser } from "../utils/UserContext";
import { Link, useHistory } from "react-router-dom";

const topToShow:number = 5;
function BestScore (){
    const { user, cleanUser } = useUser();

    const [bestScores, setBestScores] = useState<UserScore[]>([]);
    const [scoresToShow, setScoresToShow] = useState<UserScore[]>([]);
    const [currentUserBest, setCurrentUserBest] = useState<UserScore>();
    useEffect(()=>{
        getScoreList();
    }, [])

    useEffect(()=> {
        updateShowList();
    }, [bestScores])
    const getScoreList = async () => {
        const response = await userService.getUserScoreList();
        setBestScores(mapUserScore(response.data));
    }
    
    const mapUserScore = (data:[]): UserScore[] => {
        return data.map((user, index) => new UserScore(user, index+1));
    }

    const updateCurrentUserBest = () => {
        if(!user) return
        const userScore = currentUserScore(bestScores, user);
        if(!!userScore) {
            const rank = bestScores.indexOf(userScore) + 1
            user.updateBestScore(userScore, rank);
        }
    }

    const updateShowList = () => {
        if(bestScores.length === 0){
            return;
        }
        const length = Math.min(topToShow, bestScores.length);
        const result: UserScore[] = [];
        for (let i = 0; i < length; i++) {
            result.push(bestScores[i]);
        }
        if(!!user && !currentUserScore(result,user)){
            const score = currentUserScore(bestScores, user);
            if(!!score){
                setCurrentUserBest(score)
            }
        }
        setScoresToShow(result)
    }

    const currentUserScore = (scoreList: UserScore[], user: UserData): UserScore | undefined => {
        return scoreList.find(score => score.user_pk === user.pk)
    }

    const history = useHistory();
    const restart = () => {
        if(!!user){
            history.push(`/start/${user.pk}`);
        } else {
            history.push('/');
        }
    }
    return (
        <div className="container">
            <h2>High Scores</h2>
            {scoresToShow.length > 0 && 
                <div className="flexbox-center flexbox-column text-container">
                    <div className="flexbox-center">
                        {scoresToShow.map((score) => 
                            
                            <div key={score.user_pk} className="green-text flexbox-row">
                                <p>{score.rank}. {score.username}</p>
                                <div className="dash-line"></div>
                                <p>{score.score}</p>
                            </div>
                            
                        )}
                    </div>
                    {!!currentUserBest && 
                        <div className="flexbox-center flexbox-column">
                            <div className="dot-line"></div>

                            <div className="green-text flexbox-row">
                                <p>{currentUserBest.rank}. {currentUserBest.username}</p>
                                <div className="dash-line"></div>
                                <p>{currentUserBest.score}</p>
                            </div>
                        
                        </div>
                        
                        }
                    
                </div>
                    
            }
            <div className="flexbox-row">
                <div className="shadow-wrapper"><button className="game-button" onClick={cleanUser}><Link to={'/'}>Page d'accueil</Link></button></div>
                <div className="shadow-wrapper"><button className="game-button" onClick={restart}>Rejouer</button></div>
            </div>
            
        </div>
        
    )
}

export default BestScore