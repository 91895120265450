// UserInfoPage.tsx
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import userService from '../services/UserService';
import { useHistory } from 'react-router-dom';
import { UserForm } from '../utils/interfaces';
import GameRules from '../components/GameRules';
import { useUser } from '../utils/UserContext';
import AlertBox from '../components/AlertBox';

function UserInfoPage() {
  const [formData, setFormData] = useState<UserForm>({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    phone_number: null,
    job: null,
    newsletter: false,
    rgpd_accept:false,
  });
  const [isFormValid,setIsFormValid] = useState<boolean>(false);

  const { user, newUser } = useUser();

  const [showRules, setShowRules] = useState<boolean>(false);
  const [checkNewsLetter, setCheckNewsLetter] = useState<boolean>(false);
  const [checkRGPD, setCheckRGPD] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if(isComplete()) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    };
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (name === 'newsletter'){
      const newsletter = !checkNewsLetter
      setCheckNewsLetter(newsletter)
      setFormData({ ...formData, [name]:newsletter})
    } else {
      const rgpd = !checkRGPD
      setCheckRGPD(rgpd);
      setFormData({ ...formData, [name]:rgpd})
    }
  }

  const history = useHistory();
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    checkBlank();
    if(checkRGPD){
      submitUser(formData);
    } else {
      setMessage("Veuillez accepter les conditions liées au traitement de vos données afin d'accéder au jeu." );
      setOpenAlert(true);
    }
  };
  const submitUser = (userinfo: UserForm) => {
    userService.postUser(userinfo)
      .then((response)=> {
        newUser(response.data);
        setShowRules(true);
      })
      .catch ((error: any) => {
        if ( error.response.status === 400){
          if(!!error.response.data.email){
            console.log(error)
            if(error.response.data.email[0] === "user with this email already exists."){
              newUser(formData);
              userExist();
            } else {
              setMessage("Veuillez entrer un address e-mail valid.");
              setOpenAlert(true);
            }
            
          } else if(!!error.response.data.phone_number) {
            setMessage("Le format du numéro de téléphone doit comporter l'indicatif du pays.");
            setOpenAlert(true);
          }
        } else {
          console.warn('Error sending user data:', error); 
        }
      })
  }

  const checkBlank = () => {
    if(formData.phone_number === '') {
      setFormData({...formData, phone_number: null});
    }
    if(formData.job === '') {
      setFormData({...formData, job: null})
    }
  }
  const isComplete = ():boolean => {
    return formData.username !== '' && formData.email !== '' && formData.company !== '' && formData.rgpd_accept;
  }
  const userExist = () => {
    history.push('/user_exist')
  }

  const getPropsForRulePage = () => {
    if(!!user){
      return user.pk;
    } else {
      return null;
    }
  }

  return (
    <div className='container'>
      <h1>Identifiez-vous</h1>
      <form id="userInfoForm" className='user-form' onSubmit={handleSubmit}>
        <div className='form-content'>
          <div>
            <label htmlFor="username">Pseudo<sup>*</sup>:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </div>                    
          <div>
            <label htmlFor="first_name">Prénom<sup>*</sup>:</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="last_name">Nom<sup>*</sup>:</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email<sup>*</sup>:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="company">Entreprise<sup>*</sup>:</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="tel">Téléphone:</label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={!!formData.phone_number ? formData.phone_number : ''}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="job">Fonction:</label>
            <input
              type="text"
              id="job"
              name="job"
              value={!!formData.job ? formData.job : ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='checkbox-wrapper'>
            <input
              className='checkbox'
              type="checkbox"
              name="newsletter"
              checked={checkNewsLetter}
              onChange={handleCheckboxChange}
            />
            <span>Je souhaite m'abonner à la newsletter.</span>
        </div>
        <div className='checkbox-wrapper'>
            <input
              className='checkbox'
              type="checkbox"
              name="rgpd_accept"
              checked={checkRGPD}
              onChange={handleCheckboxChange}
            />
            <span>En cochant cette case j'autorise Chouette à traiter mes données dans le cadre du jeu ainsi que de son offre de services.<sup>*</sup></span>
            <p>Nous vous informons que vous disposez du droit d'accéder, de rectifier et de retirer les données fournies conformément au règlement général sur la protection des données (RGPD). Si vous souhaitez exercer ces droits, veuillez nous contacter à l'adresse <i>contact@chouette.vision</i>.</p>
        </div>
        <div className='flexbox-row'>
          <div className='shadow-wrapper'><button className="game-button" type="button" onClick={()=>{setShowRules(true)}}>Règles du jeu</button></div>
          <div className='shadow-wrapper'><button className="game-button" type="submit">Soumettre</button></div>
        </div>
      </form>
      {openAlert && 
      <AlertBox setOpenAlert={setOpenAlert} message={message} />
      }
      {showRules && 
      <GameRules userId={getPropsForRulePage()} closeRules={setShowRules} />
      }
    </div>
  );
}

export default UserInfoPage;
